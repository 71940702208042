
import { defineComponent } from '@vue/runtime-core';
import { getSelected } from '../Chooser/helpers';
import { setToast } from '@/store';

export default defineComponent({
    methods: {
        async submit(){
            const ids = getSelected();
            if(!ids)
                return;
                
            const areYouSure = this.$parse('אתה בטוח שאתה', 'את בטוחה שאת');
            if(confirm(`${areYouSure} רוצה למחוק את השאלות שנבחרו?`)){
                try{
                    await this.$request('/questions', 'delete', { ids });
                    this.$router.push('/');
                    setToast({ text: 'השאלות נמחקו בהצלחה' });
                }catch(error){
                    console.error(error);
                    setToast({ text: 'משהו השתבש. נסו שוב' });
                }
            }
        }
    }
});
